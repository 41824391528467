import { MixpanelEventDefine } from "./types";

export enum ViewType {
  Virtual = "Virtuelle",
  Classic = "Classique",
}

export enum PageType {
  Room = "Chambre",
  Home = "Accueil",
  Payment = "Tunnel de vente",
  Other = "Autre",
}

export enum UpgradeArea {
  ROOM = "Visite chambre",
  BOOK = "Tunnel réservation",
}

export const pathToPageType = (path: string): PageType => {
  if (path === "/") {
    return PageType.Home;
  }
  if (path.startsWith("/room")) {
    return PageType.Room;
  }
  if (path.startsWith("/payment") || path.startsWith("/confirmation")) {
    return PageType.Payment;
  }
  return PageType.Other;
}

export const mixpanelEventsDefines = {
  // Default properties to copy/paste for new events
  template: {
    name: "template",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  viewPage: {
    name: "Page visitée",
    properties: {
      path: {
        type: "string",
        label: "URL",
        required: true,
      },
      pageType: {
        type: "string",
        label: "Type de page",
        required: true,
      },
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  viewWebsite: {
    name: "Site visité",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  updateLanguage: {
    name: "Gestion de langue",
    properties: {
      browserLanguage: {
        type: "string",
        label: "Langue du navigateur",
        required: true,
      },
      changedLanguage: {
        type: "boolean",
        label: "Langue modifiée",
        required: true,
      },
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  clickOnSearchbar: {
    name: "Clic sur la barre de recherche",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      startDate: {
        type: "boolean",
        label: "Clic date départ",
        required: false,
      },
      endDate: {
        type: "boolean",
        label: "Clic date arrivée",
        required: false,
      },
      travellerType: {
        type: "string[]",
        label: "Type voyageur",
        required: false,
      },
      animals: {
        type: "boolean",
        label: "Animaux",
        required: false,
      },
      displayType: {
        type: "string",
        label: "Type affichage",
        required: false,
      },
      minAmount: {
        type: "boolean",
        label: "Montant minimum",
        required: false,
      },
      maxAmount: {
        type: "boolean",
        label: "Montant maximum",
        required: false,
      },
      floors: {
        type: "number[]",
        label: "Sélection étage",
        required: false,
      },
      discount: {
        type: "boolean",
        label: "Code avantage",
        required: false,
      },
    },
  },
  expressCheckout: {
    name: "Click réservation express",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      duration: {
        type: "number",
        label: "Nombre de jours réservés",
        required: true,
      },
      roomCategory: {
        type: "string",
        label: "Catégorie de chambre",
        required: true,
      },
    },
  },
  extraSelection: {
    name: "Sélection extra",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      types: {
        type: "string[]",
        label: "Types d'extra",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant total",
        required: true,
      },
    },
  },
  extraValidation: {
    name: "Validation extra",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      types: {
        type: "string[]",
        label: "Types d'extra",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant total",
        required: true,
      },
    },
  },
  cancellingSelection: {
    name: "Sélection condition",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      cancellingId: {
        type: "string",
        label: "ID de condition",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant condition",
        required: true,
      },
      label: {
        type: "string",
        label: "Libellé condition",
        required: true,
      },
    },
  },
  cancellingValidation: {
    name: "Validation condition",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      cancellingId: {
        type: "string",
        label: "ID de condition",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant condition",
        required: true,
      },
      label: {
        type: "string",
        label: "Libellé condition",
        required: true,
      },
    },
  },
  viewRoomCategory: {
    name: "Visualisation catégorie actuelle",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      roomCategory: {
        type: "string",
        label: "Catégorie de chambre",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant",
        required: true,
      },
    },
  },
  viewPaymentInformation: {
    name: "Détail des informations",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  viewConfirmation: {
    name: "Confirmation de réservation",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  clickOnWebsite: {
    name: "Clic site web",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      area: {
        type: "string",
        label: "Zone",
        required: true,
      },
    },
  },
  clickOnPhone: {
    name: "Clic téléphone",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      area: {
        type: "string",
        label: "Zone",
        required: true,
      },
    },
  },
  clickOnMail: {
    name: "Clic mail",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      area: {
        type: "string",
        label: "Zone",
        required: true,
      },
    },
  },
  clickOnAddress: {
    name: "Clic adresse",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      area: {
        type: "string",
        label: "Zone",
        required: true,
      },
    },
    
  },
  clickOnBookingElement: {
    name: "Clic élément réservation",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      addCalendar: {
        type: "boolean",
        label: "Ajout au calendrier",
        required: false,
      },
      copyID: {
        type: "boolean",
        label: "Copie ID réservation",
        required: false,
      },
      newBooking: {
        type: "boolean",
        label: "Nouvelle réservation",
        required: false,
      },
      cancel: {
        type: "boolean",
        label: "Annulation réservation",
        required: false,
      },
      print: {
        type: "boolean",
        label: "Imprimer",
        required: false,
      },
      displayInformation: {
        type: "boolean",
        label: "Afficher informations hôtel",
        required: false,
      },
      visit: {
        type: "boolean",
        label: "Visite chambre",
        required: false,
      },
    },
  },
  selectCategory: {
    name: "Sélection catégorie",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      category: {
        type: "string[]",
        label: "Catégorie de chambre",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant",
        required: true,
      },
    },
  },
  clickOnRoomInformation: {
    name: "Clic page info chambre",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
    },
  },
  areaVisited: {
    name: "Zone visitée",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      sweepId: {
        type: "string",
        label: "Sweep ID",
        required: true,
      },
      roomId: {
        type: "string",
        label: "Room ID",
        required: false,
      },
    },
  },
  upgradeCategory: {
    name: "Upgrade catégorie",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      category: {
        type: "string",
        label: "Catégorie de chambre",
        required: true,
      },
      price: {
        type: "number",
        label: "Montant",
        required: true,
      },
      area: {
        type: "string",
        label: "Zone",
        required: true,
      },
    },
  },
  bookingDetails: {
    name: "Achat réalisé",
    properties: {
      hotelName: {
        type: "string",
        label: "Nom de l'hôtel",
        required: true,
      },
      viewType: {
        type: "string",
        label: "Type de vue",
        required: false,
      },
      rooms: {
        type: "number",
        label: "Chambres",
        required: true,
      },
      nbDaysBooked: {
        type: "number",
        label: "Nombre de jours réservés",
        required: true,
      },
      totalAmount: {
        type: "number",
        label: "Montant total",
        required: true,
      },
      hasExtras: {
        type: "boolean",
        label: "Extras",
        required: true,
      },
      nbExtras: {
        type: "number",
        label: "Nombre d'extras",
        required: false,
      },
      extrasTotalAmount: {
        type: "number",
        label: "Montant extras",
        required: false,
      },
      extraTypes: {
        type: "string[]",
        label: "Type d'extra",
        required: false,
      },
      hasCondition: {
        type: "boolean",
        label: "Conditions",
        required: true,
      },
      conditionAmount: {
        type: "number",
        label: "Montant condition",
        required: false,
      },
      conditionType: {
        type: "string",
        label: "Type de condition",
        required: false,
      },
      roomCategory: {
        type: "string",
        label: "Catégorie de chambre",
        required: true,
      },
      roomNumber: {
        type: "string",
        label: "Numéro de chambre",
        required: true,
      },
      ageRanges: {
        type: "string[]",
        label: "Tranches d'âge",
        required: true,
      },
    },
  },
} satisfies Record<string, MixpanelEventDefine>;

export type MixpanelEventsDefinesType = typeof mixpanelEventsDefines;