import React, { createContext, useContext, useEffect, useRef } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelEventProperty } from "./types";
import { mixpanelEventsDefines, MixpanelEventsDefinesType } from "./defines";
import { HotelDataContext } from "../HotelDataProvider";
export type MixpanelEvents = { [K in keyof MixpanelEventsDefinesType]: {
  // @ts-ignore
[P in keyof MixpanelEventsDefinesType[K]['properties'] as MixpanelEventsDefinesType[K]['properties'][P]['required'] extends true ? P : never]:
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'string' ? string :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'number' ? number :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'boolean' ? boolean :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'date' ? Date :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'string[]' ? string[] :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'number[]' ? number[] : never } & {
  // @ts-ignore
[P in keyof MixpanelEventsDefinesType[K]['properties'] as MixpanelEventsDefinesType[K]['properties'][P]['required'] extends false ? P : never]?:
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'string' ? string :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'number' ? number :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'boolean' ? boolean :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'date' ? Date :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'string[]' ? string[] :
// @ts-ignore
MixpanelEventsDefinesType[K]['properties'][P]['type'] extends 'number[]' ? number[] : never } };
type TrackEventMethod = <T extends keyof MixpanelEvents>(event: T, properties: MixpanelEvents[T]) => void;
type UpdateUserMethod = (properties: Record<string, any>) => void;
interface MixpanelContextData {
  trackEvent: TrackEventMethod;
  updateUser: UpdateUserMethod;
}
const mapMixpanelEventProperties = <T extends keyof MixpanelEvents,>(event: T, properties: MixpanelEvents[T]): Record<string, any> => {
  const eventDefine = mixpanelEventsDefines[event];
  const result: Record<string, any> = {};
  for (const key in properties) {
    const property = eventDefine.properties[key as keyof typeof eventDefine.properties] as MixpanelEventProperty;
    result[property.label] = properties[key];
  }
  return result;
};
export const MixpanelContext = createContext<MixpanelContextData>({
  trackEvent: (() => {}) as TrackEventMethod,
  updateUser: (() => {}) as UpdateUserMethod
});
export const MixpanelProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  // Ref to avoid re rendering when mixpanel is initialized
  const isMixpanelInitializedRef = useRef<boolean>(false);
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN !== undefined) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN, {
        api_host: 'https://api-eu.mixpanel.com',
        loaded: () => {
          isMixpanelInitializedRef.current = true;
        }
      });
      mixpanel.identify();
    }
  }, []);
  const trackEvent: TrackEventMethod = (event, properties) => {
    if (isMixpanelInitializedRef.current) {
      mixpanel.track(mixpanelEventsDefines[event].name, mapMixpanelEventProperties(event, properties));
    }
  };
  const updateUser = (properties: Record<string, any>) => {
    if (isMixpanelInitializedRef.current) {
      mixpanel.people.set(properties);
    }
  };
  return <MixpanelContext.Provider value={{
    trackEvent,
    updateUser
  }} data-sentry-element="unknown" data-sentry-component="MixpanelProvider" data-sentry-source-file="MixpanelContext.tsx">
      {children}
    </MixpanelContext.Provider>;
};